import { FC, useEffect, useState } from "react";
import { SidebarContainer } from "../styles/sidebar";
import { Menu } from "antd";
import { useLocation } from "react-router-dom";
import SideBarLogo from "./SideBarLogo";
import { useSidebarItems } from "../constant/sidebarItems";

interface Items {
  key: React.Key;
  icon: React.ReactNode;
  children: Items[] | undefined;
  label: React.ReactNode;
  type: "group" | undefined;
  disabled: boolean | undefined;
}
const Sidebar: FC = () => {
  let location = useLocation();
  const currentPath = location.pathname;
  const [activeKey, setActiveKey] = useState<string>(currentPath);
  const items = useSidebarItems(activeKey);
  const a = (item: Items) => {
    if (item && item.key) {
      if (item.children && item.children.length > 0) {
        item.children.forEach((i) => a(i));
      } else {
        if (currentPath === (item.key as string))
          setActiveKey(item.key as string);
      }
    } else {
      return setActiveKey(currentPath);
    }
  };
  useEffect(() => {
    (items as Items[]).forEach((item) => {
      a(item);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPath]);
  return (
    <SidebarContainer width={290}>
      <div>
        <SideBarLogo />
        <Menu
          defaultSelectedKeys={["dashboard"]}
          mode="inline"
          items={items}
          className="menu"
          selectedKeys={[activeKey]}
        />
      </div>
    </SidebarContainer>
  );
};

export default Sidebar;
