import { Button, Col, Form, Input, Select, Space } from "antd";
import { FC, useEffect, useState } from "react";
import { FormItem } from "src/components/UiKit/FormItem";
import { VoyageContainer } from "../../../styles";
import { INewDailyReportValues } from "../../../models";
import { useDailyReport } from "../../../context";
import usePublicApiStore from "src/store/publicApi";
import { SDatePicker } from "src/components/UiKit/DatePicker";
import {
  // PositionConstant,
  TradeStatus,
  shipStatus,
} from "src/modules/ShipDailyReport/constant/shipState";
import dayjs from "dayjs";
import { SPopconfirm } from "src/modules/AddShips/components/AddNewShip/components/popconfirm";
const { Option } = Select;
export const Voyage: FC = () => {
  const {
    func: { onFinishDailyReport },
    forms: { VoyageForm },
    value: { loading },
  } = useDailyReport()!;
  const { ships } = usePublicApiStore();
  const { TextArea } = Input;

  const onFinish = (values: INewDailyReportValues) => {
    onFinishDailyReport(values);
  };

  const [submittable, setSubmittable] = useState<boolean>(false);
  const values = Form.useWatch([], VoyageForm);
  useEffect(() => {
    VoyageForm?.validateFields({ validateOnly: true }).then(
      () => {
        setSubmittable(true);
      },
      () => {
        setSubmittable(false);
      }
    );
  }, [values, VoyageForm]);
  return (
    <Form form={VoyageForm} onFinish={onFinish}>
      <VoyageContainer className="flex gap-[24px]">
        <Col span={24}>
          <div className="titleHolder pt-[16px]">
            Please insert the voyage ship information.
          </div>
        </Col>
        <Col span={24} className="flex gap-[24px] pt-[16px]">
          <FormItem
            name={"shipId"}
            label="Ship name"
            rules={[{ required: true }]}
            className="w-[33%] !mb-0"
          >
            <Select
              allowClear
              style={{ width: "100%" }}
              placeholder="select"
              optionLabelProp="label"
              showSearch
              className="selectOption"
            >
              {ships?.data?.map((item, index) => (
                <Option key={index} value={item.id} label={item.abbreviation}>
                  <Space>{item.name}</Space>
                </Option>
              ))}
            </Select>
          </FormItem>
          <FormItem
            name={"reportDate"}
            label="Report date"
            rules={[{ required: true }]}
            className="w-[33%] !mb-0"
            initialValue={dayjs()}
          >
            <SDatePicker className="w-[100%]" />
          </FormItem>
          <FormItem
            name={"tradeStatus"}
            label="Trade Status"
            className="w-[33%] !mb-0"
          >
            <Select
              allowClear
              style={{ width: "100%" }}
              placeholder="select"
              optionLabelProp="label"
              showSearch
              className="selectOption"
            >
              {TradeStatus?.map((item, index) => (
                <Option key={index} value={item.id} label={item.name}>
                  <Space>{item.name}</Space>
                </Option>
              ))}
            </Select>
          </FormItem>
        </Col>
        <Col span={24} className="flex gap-[24px] items-center">
          <FormItem
            name={"shipStatus"}
            label="Ship Status"
            className="w-[32%] !mb-0"
          >
            <Select
              allowClear
              style={{ width: "100%" }}
              placeholder="select"
              optionLabelProp="label"
              showSearch
              className="selectOption"
            >
              {shipStatus?.map((item, index) => (
                <Option key={index} value={item.id} label={item.name}>
                  <Space>{item.name}</Space>
                </Option>
              ))}
            </Select>
          </FormItem>
          {/* <FormItem
            name={"position"}
            label="Position"
            className="w-[32%] !mb-0"
          >
            <Select
              allowClear
              style={{ width: "100%" }}
              placeholder="select"
              optionLabelProp="label"
              showSearch
              className="selectOption"
            >
              {PositionConstant?.map((item, index) => (
                <Option key={index} value={item.id} label={item.name}>
                  <Space>{item.name}</Space>
                </Option>
              ))}
            </Select>
          </FormItem> */}
        </Col>
        {/* <Col span={24} className="flex gap-[24px] items-center">
          <FormItem name={"vlsfo"} label="VLSFO" className="w-[32%] !mb-0">
            <SInput placeholder="Insert" />
          </FormItem>
          <FormItem name={"mgo"} label="MGO" className="w-[32%] !mb-0">
            <SInput placeholder="Insert" />
          </FormItem>
        </Col> */}
        <Col span={24}>
          <div className="flex flex-row gap-[24px]">
            <FormItem name={"voyage"} label="Voyage" className="w-[50%] !mb-0">
              <TextArea
                placeholder="Description"
                rows={4}
                style={{
                  height: 94,
                  resize: "none",
                  borderRadius: 12,
                  background: "#F4F7FE",
                }}
              />
            </FormItem>
            <FormItem
              name={"currentSituationReport"}
              label="Current Situation Report"
              className="w-[50%] !mb-0"
            >
              <TextArea
                placeholder="Description"
                rows={4}
                style={{
                  height: 94,
                  resize: "none",
                  borderRadius: 12,
                  background: "#F4F7FE",
                }}
              />
            </FormItem>
          </div>
        </Col>
        <Col span={24}>
          <div className="flex flex-row gap-[24px]">
            <FormItem name={"action"} label="Action" className="w-[50%] !mb-0">
              <TextArea
                placeholder="Description"
                rows={4}
                style={{
                  height: 94,
                  resize: "none",
                  borderRadius: 12,
                  background: "#F4F7FE",
                }}
              />
            </FormItem>
            <FormItem
              name={"futurePlan"}
              label="Future plan"
              className="w-[50%] !mb-0"
            >
              <TextArea
                placeholder="Description"
                rows={4}
                style={{
                  height: 94,
                  resize: "none",
                  borderRadius: 12,
                  background: "#F4F7FE",
                }}
              />
            </FormItem>
          </div>
        </Col>
        <Col span={24}>
          <div className=" flex gap-[16px] footer">
            <SPopconfirm
              description="Are you sure you want to reset all the information you filled out?"
              onConfirm={() => {
                VoyageForm?.resetFields();
              }}
            >
              <Button className="w-[100px]">Reset</Button>
            </SPopconfirm>
            <Button
              type="primary"
              className="w-[100px] justify-center items-center"
              onClick={VoyageForm?.submit}
              disabled={!submittable}
              loading={loading}
            >
              Finish
            </Button>
          </div>
        </Col>
      </VoyageContainer>
    </Form>
  );
};
