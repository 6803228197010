import { FC, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { PageTitleKeysType, usePageTitle } from "../hook/usePageTitle";
import { HeaderContainer } from "../styles/header";
import { Avatar, Col, Dropdown, MenuProps, Popover, Row } from "antd";
import { ROUTES } from "src/models/enums";
import { UserOutlined } from "@ant-design/icons";
import useGlobalStore from "src/store/global";
import { USER_DATA } from "src/constants";
import { useLogout } from "src/hook";
import { EditProfile } from "src/modules/editProfile";
import { ChangePassword } from "src/modules/changePassword";

const Header: FC = () => {
  let location = useLocation();
  const pageTitle = usePageTitle(location.pathname as PageTitleKeysType);
  const [userRole, setUserRole] = useState<string[]>([]);
  const [showEditProfile, setShowEditProfile] = useState<boolean>(false);
  const [showChangePassword, setShowChangePassword] = useState<boolean>(false);
  const { userInfo } = useGlobalStore();
  useEffect(() => {
    const userRoleInLocal = localStorage.getItem(USER_DATA);
    if (userRoleInLocal) {
      setUserRole(JSON.parse(userRoleInLocal));
    }
  }, []);
  const { logoutHandler } = useLogout();
  const editProfileModalHandler = () => {
    setShowEditProfile(true);
  };
  const changePasswordModalHandler = () => {
    setShowChangePassword(true);
  };
  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <div
          className="flex gap-[5px] py-[10px]"
          onClick={editProfileModalHandler}
        >
          <span className="material-icons w-[24px] h-[24px] text-[#A3AED0]">
            person
          </span>
          <div> Edit Profile</div>
        </div>
      ),
    },
    {
      type: "divider",
    },
    {
      key: "2",
      label: (
        <div
          className="flex gap-[5px] py-[10px]"
          onClick={changePasswordModalHandler}
        >
          <span className="material-icons w-[24px] h-[24px] text-[#A3AED0]">
            lock
          </span>
          Change password
        </div>
      ),
    },
    {
      type: "divider",
    },
    {
      key: "3",
      label: (
        <div onClick={logoutHandler} className="flex gap-[5px] py-[10px]">
          <span className="material-icons w-[24px] h-[24px] text-[#A3AED0]">
            logout
          </span>
          Log Out
        </div>
      ),
    },
    {
      type: "divider",
    },
  ];
  return (
    <HeaderContainer>
      <Row className="content">
        <Col span={12}>
          {location.pathname === ROUTES.home && (
            <p className="hello">{`Hi ${userInfo?.firstName || ""} ${
              userInfo?.lastName || ""
            }`}</p>
          )}
          <h2 className="title">{pageTitle}</h2>
        </Col>
        <Col span={12} className="notice">
          {/* <Badge count={4} overflowCount={10}>
            <span className="material-icons icons">description</span>
          </Badge>
          <Badge count={8} overflowCount={10}>
            <span className="material-icons icons">notifications</span>
          </Badge> */}
          <Dropdown menu={{ items }} trigger={["hover"]} placement="bottomLeft">
            <div className="avatar cursor-pointer">
              <Avatar size={59} icon={<UserOutlined />} />
              <div className="flex justify-between items-center gap-[32px] ">
                <div>
                  <div className="username">
                    {userInfo?.firstName} {userInfo?.lastName}
                  </div>

                  {userRole && userRole.join(" , ").length > 30 ? (
                    <Popover title={userRole.join(" , ")}>
                      <div className="userRole">
                        {userRole.join(" , ").substring(0, 30).concat("...")}
                      </div>
                    </Popover>
                  ) : (
                    <div className="userRole">{userRole?.join(" , ")}</div>
                  )}
                </div>
                <span className="material-icons text-[#A3AED0]">
                  keyboard_arrow_down
                </span>
              </div>
            </div>
          </Dropdown>
        </Col>
      </Row>
      <EditProfile
        isModalOpen={showEditProfile}
        setIsModalOpen={setShowEditProfile}
      />
      <ChangePassword
        isModalOpen={showChangePassword}
        setIsModalOpen={setShowChangePassword}
      />
    </HeaderContainer>
  );
};

export default Header;
