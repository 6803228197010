import { ROUTES } from "src/models/enums";
import styled from "styled-components";

export const ContentContainer = styled.div<{ path: string }>`
  width: 100%;
  /* height: 100%; */
  padding: ${(props) =>
    props.path === "/" || props.path === ROUTES.table ? "unset" : "16px 24px"};
  /* background-color: #1c2026; */
`;
