import { Layout } from "antd";
import styled from "styled-components";

const { Header } = Layout;

export const HeaderContainer = styled(Header)`
  margin-top: 37px !important;
  margin-bottom: 20px;
  .content {
    height: 66px;
    padding-inline: 24px;
  }
  .hello {
    color: var(--secondary-grey-700, #707eae);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 171.429% */
    letter-spacing: -0.28px;
    margin-bottom: unset;
  }
  .title {
    color: var(--secondary-grey-900, #2b3674);
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 42px; /* 131.25% */
    letter-spacing: -0.64px;
    margin-bottom: unset;
  }
  .icons {
    color: #a3aed0;
    font-size: 40px;
  }
  .notice {
    display: flex;
    align-items: center;
    gap: 24px;
    justify-content: flex-end;
  }
  .avatar {
    /* width: 400px; */
    display: flex;
    gap: 16px;
    justify-content: flex-end;
  }
  .username {
    color: var(--gray-03, #6c7172);
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .userRole {
    color: #2c73fc;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .ant-scroll-number {
    margin: 6px 6px;
    box-shadow: unset;
  }
  .ant-avatar {
    border: 3px solid #2c73fc;
  }
  .css-dev-only-do-not-override-5diy3c {
  }
`;
