import { ColumnsType, TableProps } from "antd/es/table";
import { FC, ReactNode, useEffect } from "react";
import { TableUI } from "src/components/UiKit/table";
import { DailyReportsTableContainer } from "../../styles";
import { SortOrder } from "antd/es/table/interface";
import { IInvoice } from "src/services/Invoice/models";
import { getRowKey } from "src/helpers/getRowKey";
import moment from "moment";
import { useChangeParams } from "src/hook/useParamsChange";
import { useDailyReport } from "../../context";
import { useSearchParams } from "react-router-dom";
import { INewAllShipDailyReportResult } from "src/services/DailyReport/models/result.model";
interface IDailyReportsType {
  key: string;
  ship: string;
  render: ReactNode;
  text: string;
  record: IInvoice;
  ReportDate: string;
}

export const DailyReportsTable: FC = () => {
  let [searchParams, setSearchParams] = useSearchParams();

  const {
    value: { pagination, dataSource, tableLoading },
    dispatch: { setPagination },
    func: { AllShipDailyReport },
  } = useDailyReport();
  const onChangeParams = useChangeParams();

  //--------------------------------------Table Columns-------------------------------------------
  const columns: ColumnsType<INewAllShipDailyReportResult> = [
    {
      title: " #",
      dataIndex: "#",
      key: "index",
      width: 38,
      align: "center",
      className: "!font-bold",
      render: (name, record, index) =>
        (pagination.current - 1) * pagination.pageSize + (index + 1),
    },
    {
      title: "Ship",
      dataIndex: "shipName",
      key: "shipName",
      width: 88,
      align: "center",
      className: "!font-bold",
      sorter: (a, b) => a.shipName.length - b.shipName.length,
      sortDirections: ["descend"],
      sortIcon: (props: { sortOrder: SortOrder }) => (
        <span className="material-icons">sort</span>
      ),
    },
    {
      title: "Report date",
      dataIndex: "reportDate",
      key: "reportDate",
      width: 150,
      align: "center",
      className: "!font-bold !px-2",
      sorter: (a, b) => a.reportDate?.length - b.reportDate?.length,
      sortDirections: ["descend"],
      sortIcon: (props: { sortOrder: SortOrder }) => (
        <span className="material-icons">sort</span>
      ),
      render: (text) => (text ? moment(text).format("YYYY-MM-DD") : ""),
    },
    {
      title: "Trade Status",
      dataIndex: "tradeStatusText",
      key: "tradeStatusText",
      width: 100,
      align: "center",
      className: "!font-bold !px-2",
      sorter: (a, b) => a.tradeStatus - b.tradeStatus,
      sortDirections: ["descend"],
      sortIcon: (props: { sortOrder: SortOrder }) => (
        <span className="material-icons">sort</span>
      ),
    },
    {
      title: "Ship Status",
      dataIndex: "shipStatusText",
      key: "shipStatusText",
      width: 100,
      align: "center",
      className: "!font-bold !px-2",
      sorter: (a, b) => a.shipStatus - b.shipStatus,
      sortDirections: ["descend"],
      sortIcon: (props: { sortOrder: SortOrder }) => (
        <span className="material-icons">sort</span>
      ),
    },
    {
      title: "Position",
      dataIndex: "positionText",
      key: "positionText",
      width: 100,
      align: "center",
      className: "!font-bold !px-2",
      sorter: (a, b) => a.position - b.position,
      sortDirections: ["descend"],
      sortIcon: (props: { sortOrder: SortOrder }) => (
        <span className="material-icons">sort</span>
      ),
    },
    {
      title: "Status",
      dataIndex: "statusUI",
      key: "statusUI",
      width: 104,
      align: "center",
      className: "!font-bold",
      sorter: (a, b) => a.stauts - b.stauts,
      sortDirections: ["descend"],
      sortIcon: (props: { sortOrder: SortOrder }) => (
        <span className="material-icons">sort</span>
      ),
    },
    {
      title: "Seen",
      dataIndex: "isSeen",
      key: "isSeen",
      width: 70,
      align: "center",
      className: "!font-bold",
      render: (value, record) => {
        return (
          <div>
            {!value ? (
              <span className="material-icons text-[#5D6881] text-[18px]">
                highlight_off
              </span>
            ) : (
              <span className="material-icons text-[#2C73FC] text-[18px]">
                check_circle_outline
              </span>
            )}
          </div>
        );
      },
    },
    {
      title: "Tools",
      dataIndex: "tools",
      key: "tools",
      width: 160,
      align: "center",
      className: "!font-bold",
    },
  ];
  //-------------------------------------Func ----------------------------------------------------

  const onChange: TableProps<IDailyReportsType>["onChange"] = (
    pagination,
    filters,
    sorter,
    extra
  ) => {};

  useEffect(() => {
    if (!searchParams.size) {
      setSearchParams({
        Limit: pagination.pageSize.toString(),
        Offset: pagination.current.toString(),
      });
    } else {
      const Limit = searchParams.get("Limit");
      const Offset = searchParams.get("Offset");
      setPagination((prev) => ({
        ...prev,
        pageSize: Limit ? +Limit : 10,
        current: Offset ? +Offset : 1,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams.size]);
  useEffect(() => {
    if (searchParams.size) {
      AllShipDailyReport();
    }
  }, [AllShipDailyReport, searchParams.size]);
  return (
    <DailyReportsTableContainer>
      <TableUI
        columns={columns}
        tableType="primary"
        onChange={onChange}
        dataSource={dataSource}
        rowKey={getRowKey}
        rowClassName={(record, index) =>
          index % 2 !== 0 ? "bg-[#F2F7FF]" : ""
        }
        pagination={{
          position: ["bottomCenter"],
          showSizeChanger: true,
          total: pagination.total,
          current: pagination.current,
          pageSize: pagination.pageSize,
          onChange: (page: number, pageSize: number) => {
            onChangeParams("Limit", pageSize.toString());
            onChangeParams(
              "Offset",
              pageSize !== pagination.pageSize ? "1" : page.toString()
            );
            setPagination((prev) => ({
              ...prev,
              current: pageSize !== prev.pageSize ? 1 : page,
              pageSize,
            }));
          },
        }}
        loading={tableLoading}
      />
    </DailyReportsTableContainer>
  );
};
