import { ROUTES } from "../../../models/enums/routes";
import { getItem } from "../helper/createItem";
import { Link } from "react-router-dom";
import { LogoutStyle } from "../styles/logout";
import { useLogout } from "src/hook";
import { Icons } from "src/components/customIcons";
import useRoutesAccessStore from "src/store/routesAccess";

export const useSidebarItems = (activeKey: string) => {
  const { logoutHandler } = useLogout();
  const { routes } = useRoutesAccessStore();
  const items = [
    getItem(
      <Link to={ROUTES.home}>{"Dashboard"}</Link>,
      ROUTES.home,
      <span className="material-icons !text-[#a3aed0] !text-[24px]	">
        dashboard
      </span>
    ),

    routes?.table
      ? getItem(
          <Link to={ROUTES.table}> {"Table"}</Link>,
          ROUTES.table,
          <span className="material-icons !text-[#a3aed0] !text-[24px]">
            table_chart
          </span>
        )
      : null,

    routes?.Report
      ? getItem(
          <Link to={ROUTES.Report}> {"Invoice report"}</Link>,
          ROUTES.Report,
          <span className="material-icons !text-[#a3aed0] !text-[24px]">
            filter_list
          </span>
        )
      : null,
    routes?.ShipDailyReport
      ? getItem(
          <Link to={ROUTES.ShipDailyReport}> {"Ship Daily Report"}</Link>,
          ROUTES.ShipDailyReport,
          <span className="material-icons !text-[#a3aed0] !text-[24px]">
            swap_vertical_circle
          </span>
        )
      : null,
    routes?.ProfitReport
      ? getItem(
          <Link to={ROUTES.ProfitReport}> {"Profit & Loss report"}</Link>,
          ROUTES.ProfitReport,
          <span className="material-icons !text-[#a3aed0] !text-[24px]">
            request_quote
          </span>
        )
      : null,
    routes?.Category
      ? getItem(
          <Link to={ROUTES.Category}> {"Category"}</Link>,
          ROUTES.Category,
          <span className="material-icons !text-[#a3aed0] !text-[24px]">
            category
          </span>
        )
      : null,
    routes?.AddShips
      ? getItem(
          <Link to={ROUTES.AddShips}> {"Ships"}</Link>,
          ROUTES.AddShips,
          <span className="material-icons !text-[#a3aed0] !text-[24px]">
            sailing
          </span>
        )
      : null,
    routes?.Commercial
      ? getItem(
          "Voyages",
          "/voyages",
          <span className="material-icons !text-[#a3aed0] !text-[24px]">
            directions_boat_filled
          </span>,
          [
            routes?.Commercial
              ? getItem(
                  <Link to={ROUTES.Commercial}> {"Commercial"}</Link>,
                  ROUTES.Commercial
                )
              : null,
          ]
        )
      : null,

    routes?.ShipManagement
      ? getItem(
          <Link to={ROUTES.ShipManagement}> {"Ship Management"}</Link>,
          ROUTES.ShipManagement,
          <img
            src={
              activeKey === ROUTES.ShipManagement
                ? "/assets/images/activeShipManagement.svg"
                : "/assets/images/shipManagement.svg"
            }
            alt="shipManagement"
            className="!text-[#a3aed0] !text-[24px]"
          />
        )
      : null,
    routes.Supplier
      ? getItem(
          <Link to={ROUTES.Supplier}> {"Supplier"}</Link>,
          ROUTES.Supplier,
          <span className=" !text-[#a3aed0] !text-[24px]">
            <Icons
              name={
                activeKey === ROUTES.Supplier ? "supplierActive" : "supplier"
              }
            />
          </span>
        )
      : null,
    routes.Requester
      ? getItem(
          <Link to={ROUTES.Requester}> {"Requester"}</Link>,
          ROUTES.Requester,
          <span className=" !text-[#a3aed0] !text-[24px]">
            <Icons
              name={
                activeKey === ROUTES.Requester ? "requesterActive" : "requester"
              }
            />
          </span>
        )
      : null,
    getItem(
      <Link to={ROUTES.Documentation}> {"Documentation"}</Link>,
      ROUTES.Documentation,
      <span className="material-icons !text-[#a3aed0] !text-[24px]">
        folder_open
      </span>
    ),
    getItem(
      "Basic Information",
      ROUTES.BaseInfo,
      <span className="material-icons !text-[#a3aed0] !text-[24px]">
        settings
      </span>,
      [
        routes.Country
          ? getItem(
              <Link to={ROUTES.Country}> {"Country"}</Link>,
              ROUTES.Country
            )
          : null,
        routes.Currency
          ? getItem(
              <Link to={ROUTES.Currency}>{"Currency"}</Link>,
              ROUTES.Currency
            )
          : null,
        getItem(
          <Link to={ROUTES.CargoGroup}>{"Cargo group"}</Link>,
          ROUTES.CargoGroup
        ),
        getItem(
          <Link to={ROUTES.CargoType}>{"Cargo type"}</Link>,
          ROUTES.CargoType
        ),
        getItem(
          <Link to={ROUTES.Charterer}>{"Charterer"}</Link>,
          ROUTES.Charterer
        ),
        getItem(
          <Link to={ROUTES.Receiver}>{"Receiver"}</Link>,
          ROUTES.Receiver
        ),
        getItem(<Link to={ROUTES.Payer}>{"Payer"}</Link>, ROUTES.Payer),
      ]
    ),
    routes.CreateRole ||
    routes.AssignActionToRole ||
    routes.CreateUser ||
    routes.AssignRoleToUser ||
    routes.FrontAccess
      ? getItem(
          "System settings",
          ROUTES.settings,
          <span className="material-icons !text-[#a3aed0] !text-[24px]">
            settings
          </span>,
          [
            routes.CreateRole
              ? getItem(
                  <Link to={ROUTES.CreateRole}> {"Create role"}</Link>,
                  ROUTES.CreateRole
                )
              : null,
            routes.AssignActionToRole
              ? getItem(
                  <Link to={ROUTES.AssignActionToRole}>
                    {"Assign action to role"}
                  </Link>,
                  ROUTES.AssignActionToRole
                )
              : null,
            routes.CreateUser
              ? getItem(
                  <Link to={ROUTES.CreateUser}> {"Create user"}</Link>,
                  ROUTES.CreateUser
                )
              : null,
            routes.AssignRoleToUser
              ? getItem(
                  <Link to={ROUTES.AssignRoleToUser}>
                    {" "}
                    {"Assign role to user"}
                  </Link>,
                  ROUTES.AssignRoleToUser
                )
              : null,
            // routes.FrontAccess
            //   ? getItem(
            //       <Link to={ROUTES.FrontAccess}>Front Access</Link>,
            //       ROUTES.FrontAccess
            //     )
            //   : null,
          ]
        )
      : null,
    routes.Flow
      ? getItem(
          <Link to={ROUTES.Flow}> {"Flow"}</Link>,
          ROUTES.Flow,
          <span className="material-icons !text-[#a3aed0] !text-[24px]">
            schema
          </span>
        )
      : null,
    routes.Agent
      ? getItem(
          <Link to={ROUTES.Agent}> {"Agents"}</Link>,
          ROUTES.Agent,
          <span className="material-icons !text-[#a3aed0] !text-[24px]">
            folder_open
          </span>
        )
      : null,

    routes.NoonReport
      ? getItem(
          <Link to={ROUTES.NoonReport}> {"Noon Report"}</Link>,
          ROUTES.NoonReport,
          <span className="material-icons !text-[#a3aed0] !text-[24px]">
            brightness_6
          </span>
        )
      : null,

    routes.Port
      ? getItem(
          <Link to={ROUTES.Port}> {"Ports"}</Link>,
          ROUTES.Port,
          <span className="material-icons !text-[#a3aed0] !text-[24px]">
            signpost
          </span>
        )
      : null,

    getItem(
      <LogoutStyle onClick={logoutHandler}>Logout </LogoutStyle>,
      "Logout",
      <LogoutStyle onClick={logoutHandler}>
        <span className="material-symbols-outlined">logout</span>
      </LogoutStyle>
    ),
  ];
  const finallyItems = items.filter((item) => !!item);
  return finallyItems;
};
