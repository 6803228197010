import { Form, Row } from "antd";
import { styled } from "styled-components";

export const HeaderContainer = styled.div`
  .ant-tabs-tab {
    color: var(--text-2, #a3aed0);
    font-family: DM Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 160% */
    padding: unset;
  }
  .ant-tabs-nav::before {
    border-bottom: 1px solid var(--shadow, #dadeec);
    padding: 24px;
  }
  .ant-tabs-ink-bar {
    height: unset !important;
  }
  .ant-tabs-nav {
    padding-bottom: 24px;
    margin-bottom: 24px !important;
  }
`;
export const AddNewDailyReportContainer = styled.div`
  height: 75%;
  border-radius: 20px;
  background: var(--0, #fff);
  box-shadow: 0px 4px 6px 0px rgba(113, 130, 183, 0.25);
  padding: 24px 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
  gap: 24px;
  .ant-tabs-nav {
    height: 80px;
    padding-bottom: unset !important;
  }
  .footer {
    border-top: 1px solid var(--shadow, #dadeec);
    padding-top: 24px;
    justify-content: flex-end;
  }
  .ant-tabs-tab {
    color: var(--text-2, #a3aed0);
    font-family: DM Sans;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: unset;
  }
  .ant-tabs-ink-bar {
    height: 2px !important;
  }
`;
export const EditVoyageContainer = styled(Row)`
  input,
  .ant-select-selection-item,
  textarea {
    color: var(--text, #001377) !important;
    font-family: DM Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.24px;
  }
  .titleHolder {
    color: var(--text, #001377);
    font-family: DM Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .inputTitle {
    color: var(--text, #001377);
    font-family: DM Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .ant-input {
    border-width: 0 !important;
  }

  .selectOptionShip {
    .ant-select-selector {
      background: var(--shadow, #dadeec) !important;
      border-radius: 12px;
      height: 48px;
    }
  }

  .ant-picker-disabled {
    box-shadow: unset !important;
  }
  .ant-picker-input > input[disabled]:hover {
    background-color: unset !important;
  }
  .ant-select-arrow {
    margin-top: unset !important;
  }
  .saveEdit {
    border-radius: 16px;
    border: 1px solid var(--info-info-600, #3b82f6);
    color: var(--info-info-600, #3b82f6);
    background: #fff;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .cancelButton {
    border-radius: 16px;
    background: var(--gray-gray-100, #f5f5f5);
    font-size: 14px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .select-compact {
    .ant-select-selector {
      border-radius: 12px 0 0 12px !important;
    }
    border-right: 1px solid var(--text-2, #a3aed0);
  }
`;
export const CargosContainer = styled(Row)`
  textarea {
    color: var(--text, #001377) !important;
    font-family: DM Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.24px;
  }
  .titleHolder {
    color: var(--text, #001377);
    font-family: DM Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .inputTitle {
    color: var(--text, #001377);
    font-family: DM Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .ant-form-item-label {
    margin-bottom: 16px !important;
  }
`;
export const DailyReportsTableContainer = styled.div`
  .ant-table-cell {
    color: var(--secondary-dark-grey-900, #1b2559);
    font-family: DM Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  tr {
    th {
      color: var(--secondary-dark-grey-900, #1b2559);
      font-family: DM Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
`;
export const ShipTabContainer = styled.div`
  .checkboxGroup {
    border-top: 1px solid var(--strock, #f3f4f5);
    border-bottom: 1px solid var(--strock, #f3f4f5);
    overflow-y: auto;
  }
  .ant-checkbox-group {
    flex-wrap: unset !important;
  }
  .ant-input-affix-wrapper {
    border-radius: 8px;
    border: 1px solid var(--text-2, #a3aed0);
    background: var(--0, #fff);
  }
  .ant-checkbox-wrapper {
    color: var(--text-5, #3e4556);

    /* Body Text/Extra-Small/Body Extra-Small (Regular) */
    font-family: DM Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 166.667% */
    letter-spacing: -0.24px;
  }
`;

export const StatusContainer = styled.div`
  .ant-checkbox-wrapper {
    color: var(--text-5, #3e4556) !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 166.667% */
    letter-spacing: -0.24px;
  }
  .borderBottom {
    border-bottom: 1px solid var(--strock, #f3f4f5);
    padding-bottom: 12px;
  }
  .ant-form-item {
    margin-bottom: unset;
    min-height: 1px !important;
  }
  .ant-form-item-control-input {
    min-height: 1px;
  }
`;
export const ShipStateContainer = styled.div`
  .ant-checkbox-wrapper {
    color: var(--text-5, #3e4556);
    font-family: DM Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 166.667% */
    letter-spacing: -0.24px;
  }
  .checkboxGroup {
    border-bottom: 1px solid var(--strock, #f3f4f5);
  }
`;
export const DateContainer = styled(Form)`
  .dateTitle {
    color: var(--text, #001377);
    font-family: DM Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;
export const EditNewDailyReportContainer = styled.div`
  .container {
    border-radius: 20px;
    background: var(--0, #fff);
    box-shadow: 0px 4px 6px 0px rgba(113, 130, 183, 0.25);
    padding: 24px 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    display: flex;
    gap: 24px;
  }
  .ant-tabs-nav {
    height: 60px;
    padding-bottom: unset !important;
  }
  .footer {
    border-top: 1px solid var(--shadow, #dadeec);
    padding-top: 24px;
    justify-content: flex-end;
  }
  .ant-tabs-tab {
    color: var(--text-2, #a3aed0);
    font-family: DM Sans;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: unset;
  }
  .ant-tabs-ink-bar {
    height: 2px !important;
  }
  .shipName {
    color: var(--text, #001377);
    font-family: DM Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .shipNameDisable {
    color: var(--text-3, #5d6881);
    font-family: "DM Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .dateContainer {
    color: var(--text-3, #5d6881);
    font-family: DM Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .dateContainerDisable {
    color: var(--text-2, #a3aed0);
    font-family: "DM Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .ant-form-item-label > label {
    height: 18px;
    margin-bottom: 8px;
  }
`;
export const VoyageContainer = styled(Row)`
  input,
  .ant-select-selection-item,
  textarea {
    color: var(--text, #001377) !important;
    font-family: DM Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.24px;
  }
  .titleHolder {
    color: var(--text, #001377);
    font-family: DM Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .inputTitle {
    color: var(--text, #001377);
    font-family: DM Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .ant-input {
    border-width: 0 !important;
  }
  .ant-select-selector input {
    margin-top: unset !important;
  }
  .select-compact {
    .ant-select-selector {
      border-radius: 12px 0 0 12px !important;
    }
    border-right: 1px solid var(--text-2, #a3aed0);
  }
`;
